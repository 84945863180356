/**
 * Copyright © 2019 Johnson & Johnson
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 * http://www.apache.org/licenses/LICENSE-2.0
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from "react";
import { Helmet } from "react-helmet";
import Page from "./Page";
import Gtm from "src/components/Gtm";

export default class IndexPage extends React.Component {
  render() {
    if (typeof window !== "undefined") {
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: "https://github.com/facebook/react/issues/10923"
          }}
        />
      );
    }
    return (
      <React.Fragment>
        <Page />
        <Helmet>
          <html lang="PL" />
          <body />
         
          
          <meta charSet="utf-8" />
          <title>
            NEUTROGENA®&nbsp;VISIBLY CLEAR®&nbsp;Spot proofing ™- Nawilżający
            krem do twarzy
          </title>
          <meta
            property="og:url"
            content="/product/60"
          />
          <meta property="og:site_name" content="neutrogena.com.pl" />
          <meta
            property="og:title"
            content="    NEUTROGENA® VISIBLY CLEAR® Spot proofing ™- Nawilżający krem do twarzy"
          />
          <meta
            property="og:image"
            content="/site/assets/Uploads/_resampled/SetSize640640-NOWY-VisiblyClear-PG-Moisturiser-Pudelko-copy.jpg"
          />
          <meta property="og:image:type" content="image/jpeg" />
          <meta property="og:image:width" content="640" />
          <meta property="og:image:height" content="640" />
          <link
            rel="image_src"
            href="/site/assets/Uploads/_resampled/SetSize200200-NOWY-VisiblyClear-PG-Moisturiser-Pudelko-copy.jpg"
          />
          <meta
            property="og:description"
            content="Nasze kosmetyki pomagają ludziom na całym świecie skutecznie pielęgnować nawet najbardziej
    wymagającą skórę. Od początku istnienia mamy ten sam, niezmienny cel. Jest nim tworzenie
    najlepszych produktów do pielęgnacji każdego rodzaju skóry."
          />
          <link
            rel="shortcut icon"
            href="/site/favicon.ico"
            type="image/x-icon"
          />
          <link rel="icon" href="/site/favicon.ico" type="image/x-icon" />
         <script src="/site/themes/simple/js/jquery-3.5.1.js" />
          <script src="/site/themes/simple/js/jquery-migrate-3.3.0.js" />
<script src="/site/themes/simple/js/jquery-migrate-1.4.1.min.js" />
          <script
            type="text/javascript"
            src="/site/themes/simple/fancybox/jquery.mousewheel-3.0.4.pack.js"
          />
          <script
            type="text/javascript"
            src="/site/themes/simple/fancybox/jquery.fancybox-1.3.4.pack.js"
          />
          <script
            type="text/javascript"
            src="/site/themes/simple/fancybox/jquery.fancybox-init.js"
          />
          <link
            rel="stylesheet"
            type="text/css"
            href="/themes/simple/fancybox/jquery.fancybox-1.3.4.css"
            media="screen"
          />
          <link
            rel="stylesheet"
            href="/themes/simple/projekktor/theme/proj.css"
            type="text/css"
            media="screen"
          />
          <script
            type="text/javascript"
            src="/site/themes/simple/projekktor/projekktor-1.2.19r188.min.js"
          />
          <script
            type="text/javascript"
            src="/site/themes/simple/projekktor/projekktor-init.js"
          />
          
          <link
            href="/themes/simple/css/style.css?t=4"
            type="text/css"
            rel="stylesheet"
          />
          <link
            href="/themes/simple/css/swp.css"
            type="text/css"
            rel="stylesheet"
          />
          <link
            href="/themes/simple/css/sp.css"
            type="text/css"
            rel="stylesheet"
          />
          <link
            href="/themes/simple/css/hand.css"
            type="text/css"
            rel="stylesheet"
          />
          <link
            href="/themes/simple/css/hb.css?t=3"
            type="text/css"
            rel="stylesheet"
          />
          <link
            href="/themes/simple/css/detox.css?t=1"
            type="text/css"
            rel="stylesheet"
          />
          <link
            href="/themes/simple/css/cellular.css?t=1"
            type="text/css"
            rel="stylesheet"
          />
          <link
            href="/themes/simple/css/skin.css?t=1"
            type="text/css"
            rel="stylesheet"
          />
          <link
            rel="stylesheet"
            type="text/css"
            href="/themes/simple/css/demo.css"
          />
          <script type="text/javascript" src="/site/themes/simple/js/core.js" />
          <script
            type="text/javascript"
            src="/site/themes/simple/js/products.js"
          />
          <script
            type="text/javascript"
            src="/site/themes/simple/js/customInput.jquery.js"
          />
          <script
            type="text/javascript"
            src="/site/themes/simple/js/customInput_init.js"
          />
          <script
            type="text/javascript"
            src="/site/themes/simple/js/jquery.typographer.common.js"
          />
          <script
            type="text/javascript"
            src="/site/index.php/themes/simple/js/jquery.typographer.orphan.js"
          />
          <script
            type="text/javascript"
            innerHTML='
<!--//--><![CDATA[//><!--
function OptanonWrapper() { Optanon.InsertHtml(&apos;<div class="fb"><div style="width:250px; height:auto; float:left;"><iframe src="//www.facebook.com/plugins/like.php?href=https://www.neutrogena.com.pl/&amp;send=false&amp;layout=button_count&amp;width=200&amp;show_faces=false&amp;font&amp;colorscheme=light&amp;action=like&amp;height=21&amp;locale=pl_PL" "scrolling="no" frameborder="0"style="border:none; overflow:hidden; width:200px; height:21px;"allowTransparency="true"></iframe></div></div>&apos;, &apos;social_id_18&apos;, null, null, &apos;4&apos;); }
//--><!]]>
'
          /></Helmet>
        <Gtm />
        ;
      </React.Fragment>
    );
  }
}
